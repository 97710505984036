@media (min-width: 768px) {
  .object-modal .modal-dialog {
    width: 80%;
    max-width: 800px;
  }
}

.object-form .form-group {
  margin-bottom: 5px;
}

.form-inline-wrap.form-inline {
  margin: -5px -5px;
}

.form-inline-wrap.form-inline .form-group {
  margin: 5px 5px;
}
