.select-icon {
  margin-right: 0.5em;
}

@media (min-width: 768px) {
  .wide-modal .modal-dialog {
    width: 80%;
    max-width: 1000px;
  }
}
