.margin-none {
  margin: 0;
}

// Margins top
.margin-top-none {
  margin-top: 0;
}

.margin-top-small {
  margin-top: 0.6rem;
}

.margin-top-medium {
  margin-top: 1.875rem;
}

.margin-top-large {
  margin-top: 3.7rem;
}

// Margins bottom
.margin-bottom-none {
  margin-bottom: 0;
}

.margin-bottom-small {
  margin-bottom: 0.6rem;
}

.margin-bottom-medium {
  margin-bottom: 1.875rem;
}

.margin-bottom-large {
  margin-bottom: 3.7rem;
}
