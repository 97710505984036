.dropzone-container {
  display: flex;
}

.dropzone {
  border-radius: 5px;
  border: 1px solid #ccc;
  text-align: center;
  align-items: center;
  flex: 1;
  margin-right: 5px;
}

.attached-file-group {
  margin-top: 1em;
}

.file-input-component {
  position: relative;
}

.file-input-file {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  z-index: 1;
}

.file-input-text {
  position: relative;
  z-index: -1;
}
