.path-history-toolbar {
  display: flex;
  &__object-select {
    width: 100%;
  }

  & > .btn-group {
    display: flex;
  }
}
