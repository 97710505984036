@import 'config/variables';
@import 'components/fileupload';
@import 'components/forms';
@import 'components/jcm-project';
@import 'components/jcm-validate';
@import 'components/jcm-files';
@import 'components/jcm-entities';
@import 'components/jcm-process';
@import 'components/draggable-modal-dialog';
@import 'components/object-browser';
@import 'components/info-panel';
@import 'components/path-history-editor';

@import 'config/typography';

@import 'theme/base';
@import 'theme/login';
@import 'theme/navbar';
@import 'theme/footer';

@import 'utils/margins';
@import 'utils/general';

@import 'bootstrap-custom';

@tailwind base;
@tailwind components;
@tailwind utilities;

$icon-font-path: '~bootstrap-sass/assets/fonts/bootstrap/';
@import '~bootstrap-sass';
