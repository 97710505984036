@media (min-width: 1920px) {
  .container {
    width: 1900px !important;
  }
}

@media (min-width: 1680px) {
  .container {
    width: 1600px !important;
  }
}

// Fix checkbox styling, ant-d clobbering bootstrap
.checkbox > label {
  font-size: 14px;
}

// Fix checkbox styling, ant-d clobbering bootstrap
form {
  input[type='radio'],
  input[type='checkbox'] {
    width: 12px !important;
    height: 12px !important;
  }
}
