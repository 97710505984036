// This class is passed to CodeMirror, which uses it to compute
// the width of the gutter.
.error-marker {
  width: 1.5em;
}

.line-actions {
  width: 1em;
}

.CodeMirror-line.ignored span {
  color: #bbb;
}

.CodeMirror {
  height: 70vh !important;
}

.CodeMirror-fullscreen {
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  height: auto;
  z-index: 9;
}
