.form-size {
  max-width: 500px;
  padding: 15px;
  margin: 0 auto;
}

.wide-form {
  max-width: 800px;
  padding: 15px;
  margin: 0 auto;
}
