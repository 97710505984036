@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables';

@media (min-width: $screen-md-min) {
  .info-panel {
    min-width: 270px;
  }
}

@media (min-width: $screen-lg-min) {
  .info-panel {
    min-width: 360px;
  }
}

@media (min-width: 1680px) {
  .info-panel {
    min-width: 500px;
  }
}
