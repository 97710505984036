#vehicle-tabs ul {
  margin-bottom: 20px;
}

.table > tbody > tr.diff-row {
  &.row-unchanged {
    background-color: #f9f9f9;
  }

  &--error > td {
    border-top-width: 0;
  }

  & > td.text {
    padding-top: 15px;
    padding-bottom: 16px;
  }

  & > td.td-checkbox {
    padding-top: 14px;
  }

  & > td.td-label {
    padding-top: 13px;
  }
}

@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/_mixins';

.dl-horizontal-process-errors {
  dd {
    @include clearfix; // Clear the floated `dt` if an empty `dd` is present
  }

  @media (min-width: $dl-horizontal-breakpoint) {
    dt {
      float: left;
      width: (300px - 20);
      clear: left;
      text-align: left;
      @include text-overflow;
    }
    dd {
      margin-left: 300px;
    }
  }
}

.tw-flex > .form-group {
  margin-bottom: 0;
}
