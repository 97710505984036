.center-page {
  margin: 0 auto;
}

.center-text {
  text-align: center;
}

ul.list {
  list-style: disc;
  padding-inline-start: 40px;
}

ol.list {
  list-style: decimal;
  padding-inline-start: 40px;
}
