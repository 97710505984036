.panel-heading .checkbox {
  margin-top: 0;
  display: inline-block;
}

// Backport of bootstrap 4's spacing utilities
$grid-breakpoints: (
  xs: 0,
  sm: $screen-sm-min,
  md: $screen-md-min,
  lg: $screen-lg-min,
);
$spacer-x-0: 0rem;
$spacer-y-0: 0rem;
$spacer-x-1: 0.25rem;
$spacer-y-1: 0.25rem;
$spacer-x-2: 0.5rem;
$spacer-y-2: 0.5rem;
$spacer-x-3: 1rem;
$spacer-y-3: 1rem;
$spacer-x-4: 1.5rem;
$spacer-y-4: 1.5rem;
$spacer-x-5: 3rem;
$spacer-y-5: 3rem;
$spacers: (
  0: (
    x: $spacer-x-0,
    y: $spacer-y-0,
  ),
  1: (
    x: $spacer-x-1,
    y: $spacer-y-1,
  ),
  2: (
    x: $spacer-x-2,
    y: $spacer-y-2,
  ),
  3: (
    x: $spacer-x-3,
    y: $spacer-y-3,
  ),
  4: (
    x: $spacer-x-4,
    y: $spacer-y-4,
  ),
  5: (
    x: $spacer-x-5,
    y: $spacer-y-5,
  ),
);
// **************************** Ported mixins ********************************
@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

// ****************************** _spacing.scss ******************************
// https://github.com/twbs/bootstrap/blob/v4-dev/scss/utilities/_spacing.scss

// Width and height

.w-100 {
  width: 100% !important;
}
.h-100 {
  height: 100% !important;
}

.mw-100 {
  max-width: 100% !important;
}
.mh-100 {
  max-height: 100% !important;
}

// Margin and Padding

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @each $prop, $abbrev in (margin: m, padding: p) {
    @each $size, $lengths in $spacers {
      $length-x: map-get($lengths, x);
      $length-y: map-get($lengths, y);

      @include media-breakpoint-up($breakpoint) {
        $min: breakpoint-min($breakpoint, $grid-breakpoints);

        @if $min {
          // everything else
          .#{$abbrev}-#{$breakpoint}-#{$size} {
            #{$prop}: $length-y $length-x !important;
          }
          .#{$abbrev}t-#{$breakpoint}-#{$size} {
            #{$prop}-top: $length-y !important;
          }
          .#{$abbrev}r-#{$breakpoint}-#{$size} {
            #{$prop}-right: $length-x !important;
          }
          .#{$abbrev}b-#{$breakpoint}-#{$size} {
            #{$prop}-bottom: $length-y !important;
          }
          .#{$abbrev}l-#{$breakpoint}-#{$size} {
            #{$prop}-left: $length-x !important;
          }
          .#{$abbrev}x-#{$breakpoint}-#{$size} {
            #{$prop}-right: $length-x !important;
            #{$prop}-left: $length-x !important;
          }
          .#{$abbrev}y-#{$breakpoint}-#{$size} {
            #{$prop}-top: $length-y !important;
            #{$prop}-bottom: $length-y !important;
          }
        } @else {
          // xs
          .#{$abbrev}-#{$size} {
            #{$prop}: $length-y $length-x !important;
          }
          .#{$abbrev}t-#{$size} {
            #{$prop}-top: $length-y !important;
          }
          .#{$abbrev}r-#{$size} {
            #{$prop}-right: $length-x !important;
          }
          .#{$abbrev}b-#{$size} {
            #{$prop}-bottom: $length-y !important;
          }
          .#{$abbrev}l-#{$size} {
            #{$prop}-left: $length-x !important;
          }
          .#{$abbrev}x-#{$size} {
            #{$prop}-right: $length-x !important;
            #{$prop}-left: $length-x !important;
          }
          .#{$abbrev}y-#{$size} {
            #{$prop}-top: $length-y !important;
            #{$prop}-bottom: $length-y !important;
          }
        }
      }
    }
  }
}

// Positioning

.pos-f-t {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: $zindex-navbar-fixed;
}

// Support tables in panels even when panel-collapse is used. The only
// change is the top level selector gets a `> .panel-collapse` added.

@import '~bootstrap-sass/assets/stylesheets/bootstrap/_mixins';

.panel > .panel-collapse {
  > .table,
  > .table-responsive > .table,
  > .panel-collapse > .table {
    margin-bottom: 0;

    caption {
      padding-right: $panel-body-padding;
      padding-left: $panel-body-padding;
    }
  }
  // Add border top radius for first one
  > .table:first-child,
  > .table-responsive:first-child > .table:first-child {
    @include border-top-radius(($panel-border-radius - 1));

    > thead:first-child,
    > tbody:first-child {
      > tr:first-child {
        border-top-left-radius: ($panel-border-radius - 1);
        border-top-right-radius: ($panel-border-radius - 1);

        td:first-child,
        th:first-child {
          border-top-left-radius: ($panel-border-radius - 1);
        }
        td:last-child,
        th:last-child {
          border-top-right-radius: ($panel-border-radius - 1);
        }
      }
    }
  }
  // Add border bottom radius for last one
  > .table:last-child,
  > .table-responsive:last-child > .table:last-child {
    @include border-bottom-radius(($panel-border-radius - 1));

    > tbody:last-child,
    > tfoot:last-child {
      > tr:last-child {
        border-bottom-right-radius: ($panel-border-radius - 1);
        border-bottom-left-radius: ($panel-border-radius - 1);

        td:first-child,
        th:first-child {
          border-bottom-left-radius: ($panel-border-radius - 1);
        }
        td:last-child,
        th:last-child {
          border-bottom-right-radius: ($panel-border-radius - 1);
        }
      }
    }
  }
  > .panel-body + .table,
  > .panel-body + .table-responsive,
  > .table + .panel-body,
  > .table-responsive + .panel-body {
    border-top: 1px solid $table-border-color;
  }
  > .table > tbody:first-child > tr:first-child th,
  > .table > tbody:first-child > tr:first-child td {
    border-top: 0;
  }
  > .table-bordered,
  > .table-responsive > .table-bordered {
    border: 0;
    > thead,
    > tbody,
    > tfoot {
      > tr {
        > th:first-child,
        > td:first-child {
          border-left: 0;
        }
        > th:last-child,
        > td:last-child {
          border-right: 0;
        }
      }
    }
    > thead,
    > tbody {
      > tr:first-child {
        > td,
        > th {
          border-bottom: 0;
        }
      }
    }
    > tbody,
    > tfoot {
      > tr:last-child {
        > td,
        > th {
          border-bottom: 0;
        }
      }
    }
  }
  > .table-responsive {
    margin-bottom: 0;
    border: 0;
  }
}

.popover-content > ul {
  padding-inline-start: 20px;
}

.input-group-addon > label {
  margin-bottom: 0;
}

.btn:focus,
.btn:active {
  outline: none !important;
}
